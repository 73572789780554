import React from 'react'
import { CONTACT_PAGE } from '../../../content/data/contactPages'
import Layout from '../../components/Layout'
import InnerLayout from '../../components/InnerLayout'
import HubspotForm from 'react-hubspot-form'

export default function Contact() {
    const pageData = CONTACT_PAGE

    return (
        <>
            <Layout
                title={pageData.mainTitle}
                forceFullLogo
                backgroundImg="/img/contactPages/message.svg"
                seoTitle="Kubevious :: Contact"
                seoDescription="Contact Us"
            >
                <InnerLayout>
                    <HubspotForm
                        portalId={pageData.hubspotPortalId}
                        formId={pageData.hubspotFormId}
                        // onSubmit={() => console.log('Submit!')}
                        // onReady={(form) => console.log('Form ready!')}
                        // loading={<div>Loading...</div>}
                    />
                </InnerLayout>
            </Layout>

        </>
    )
}
